export const FormActionTypes = {
  //Form Action Types
  SET_LOAN: "SET_LOAN",
  SET_AGE: "SET_AGE",
  SET_EMPSTATUS: "SET_EMPSTATUS",
  SET_NETINCOME: "SET_NETINCOME",
  SET_EMPYEARS: "SET_EMPYEARS",
  SET_MONTHS: "SET_MONTHS",
  SET_RENT_COST: "SET_RENT_COST",
  SET_LOAN_COSTS: "SET_LOAN_COSTS",
  SET_LOAN_PROBS: "SET_LOAN_PROBS",
  SET_EMAIL: "SET_EMAIL",
  SET_PRODUCTS: "SET_PRODUCTS",
};
