import React from 'react';
import ReactDOM from "react-dom";
import {  Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';


import Router from './routes/Routes';
import Header from "./components/Header/Header";

import Footer from "./components/Footer/Footer";

function App() {
    return (
            <BrowserRouter>
                <>
                    <Header />
                    <Router />
                <Footer />
                </>
            </BrowserRouter>

    );
}
export default App;
