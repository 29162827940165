//Importing Action types:
import axios from "axios";
import { FormActionTypes } from "./formActionTypes";
const baseUrl = process.env.REACT_APP_SERVER_URL;

//Actions or Events:
export const setLoan = (loan) => {
  return {
    type: FormActionTypes.SET_LOAN,
    payload: loan,
  };
};

export const setAge = (age) => {
  return {
    type: FormActionTypes.SET_AGE,
    payload: age,
  };
};
export const setEmpStatus = (status) => {
  return {
    type: FormActionTypes.SET_EMPSTATUS,
    payload: status,
  };
};

export const setTurnover = (turnover) => {
  return {
    type: FormActionTypes.SET_TURNOVER,
    payload: turnover,
  };
};

export const setNetIncome = (income) => {
  return {
    type: FormActionTypes.SET_NETINCOME,
    payload: income,
  };
};
export const setEmpYearsAct = (years) => {
  return {
    type: FormActionTypes.SET_EMPYEARS,
    payload: years,
  };
};

export const setMonthsPerYear = (months) => {
  return {
    type: FormActionTypes.SET_MONTHS,
    payload: months,
  };
};
export const setRentCosts = (rent) => {
  return {
    type: FormActionTypes.SET_RENT_COST,
    payload: rent,
  };
};

export const setLoanCosts = (costs) => {
  return {
    type: FormActionTypes.SET_LOAN_COSTS,
    payload: costs,
  };
};

export const setLoanProbs = (loanProbs) => {
  return {
    type: FormActionTypes.SET_LOAN_PROBS,
    payload: loanProbs,
  };
};

export const setEmail = (email) => {
  return {
    type: FormActionTypes.SET_EMAIL,
    payload: email,
  };
};

export const getProducts = async () => {
  try {
    let res = await axios.get(baseUrl + "/it-products/italian-products");
    return res.data;
  } catch (error) {
    console.log("something went wrong in getPRODUCTS", error);
    throw error;
  }
};

export const setProducts = (products) => {
  return {
    type: FormActionTypes.SET_PRODUCTS,
    payload: products,
  };
};

export const SendfullState = async (state) => {
  try {
    let res = await axios.post(baseUrl + "/it-products/user-data", state);
    console.log(res.data);
  } catch (error) {
    console.log("error", error);
  }
};
