import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./Step/Styles.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import younited from "../assets/younited.png";
import time from "../assets/time.png";
import stats from "../assets/stats.png";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";

import { SendfullState } from "../services/Form/FormActions";

export const Provider = (props) => {
  const {
    logo,
    alt_logo,
    clickUrl,
    title,
    minimum_loan,
    maximum_loan,
    interest,
    loan_rate_indication,
    ...rest
  } = props;

  const dispatch = useDispatch();

  //Selecting useful bits of card state:
  const [loan, setLoan] = useState("");

  const amount = parseFloat(window.sessionStorage.getItem("amount"));
  const rent = parseFloat(window.sessionStorage.getItem("rentCost"));
  const loanCost = parseFloat(
    JSON.parse(window.sessionStorage.getItem("loanCost"))
  );
  const empYears = JSON.parse(window.sessionStorage.getItem("empYears"));
  const status = window.sessionStorage.getItem("status");
  const age = JSON.parse(window.sessionStorage.getItem("birth"));
  const loanProbs = window.sessionStorage.getItem("loanProbs");
  const income = parseFloat(
    JSON.parse(window.sessionStorage.getItem("income"))
  );
  const months = JSON.parse(window.sessionStorage.getItem("months"));
  const email = window.sessionStorage.getItem("email");

  const [offer, setOffer] = useState({});

  const user = {
    amount: amount,
    age: age,
    status: status,
    income: income,
    empYears: empYears,
    months: months,
    rent: rent,
    loanCost: loanCost,
    loanProbs: loanProbs,
    email: email,
  };

  const location = useLocation();
  const Navigate = useNavigate();
  let locState = "fromStep11";
  useEffect(() => {
    setLoan(window.sessionStorage.getItem("amount"));
  }, []);

  var obj = Object.keys(sessionStorage).reduce(function (obj, key) {
    obj[key] = sessionStorage.getItem(key);
    return obj;
  }, {});

  // useEffect(() => {

  //   console.log("----to Send----", userData);
  // }, [offer]);
  const sendAllData = (userData) => {
    SendfullState(userData);
  };
  const Next = () => {
    const userData = {
      ...user,
      product: title,
      min: minimum_loan,
      max: maximum_loan,
      rate: loan_rate_indication,
    };

    // console.log("----to Send----", userData);
    sendAllData(userData);
    Navigate("../step/12", { state: locState });
    window.open(clickUrl, "_blank");
    window.sessionStorage.setItem(
      "offer",
      JSON.stringify({
        logo,
        alt_logo,
        title,
        minimum_loan,
        maximum_loan,
        interest,
        loan_rate_indication,
      })
    );
  };
  // console.log(location.state);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card
        className="container"
        sx={{
          // minWidth: 500,
          maxWidth: 700,
          minHeight: 200,
          // display:'flex',
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "135px" }}>
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image={logo}
            alt="Younited credit"
            style={{
              width: "100px",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          />
          <Box
            sx={{
              border: 2,
              borderColor: "lightblue",
              borderRadius: 1,
              width: "120px",
            }}
          >
            <Typography
              component="div"
              variant="subtitle"
              fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
              sx={{
                marginLeft: "9px",
                marginTop: "6px",
                fontSize: 12,
                fontWeight: 600,
                maxWidth: "650px",
              }}
            >
                Ammontare del prestito:
            </Typography>
            <Typography
              component="div"
              variant="h6"
              alignSelf="center"
              justifyContent="center"
              fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
              sx={{
                margin: "0 20px 0 20px",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {loan},- €
            </Typography>
          </Box>
        </div>
        <div style={{ width: "250px", marginLeft: "9px" }}>
          <div style={{ height: "50px", position: "relative" }}>
            <Typography
              variant="subtitle"
              fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
              sx={{
                fontSize: 20,
                position: "absolute",
                marginTop: "5px",
              }}
            >
              <strong>Prestito personale </strong> <br /> {title}
            </Typography>
          </div>
          <br />
          <div>
            <Typography
              component="div"
              variant="subtitle"
              color="#2E353C"
              marginTop="5px"
              sx={{
                fontSize: 13,
                marginTop: "3px",
              }}
            >
              <strong
                style={{
                  fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                }}
              >
                  Importo minimo :
              </strong>
              {minimum_loan}
            </Typography>
          </div>
          <div>
            <Typography
              component="div"
              variant="subtitle"
              color="#2E353C"
              sx={{ fontSize: 13, marginTop: "3px" }}
            >
              <strong
                style={{
                  fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                }}
              >
                  Importo massimo :
              </strong>
              {maximum_loan}
            </Typography>
          </div>
          <div>
            <Typography
              component="div"
              variant="subtitle"
              color="#2E353C"
              sx={{ fontSize: 13, marginTop: "3px" }}
            >
              <strong
                style={{
                  fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                }}
              >
                  Tasso interesse{" "} :
              </strong>{" "}
              {loan_rate_indication}
            </Typography>
          </div>
        </div>
        <div style={{ width: "200px", margin: "auto" }}>
          <div>
            <Button
              sx={{
                marginLeft: "10px",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "7px",
                marginRight: "10px",
                justifyContent: "10px",
                width: "80%",
              }}
              variant="contained"
              data-testid="offer"
              onClick={() => {
                Next();
              }}
            >
                Richiedi
            </Button>
          </div>
          <Typography
            component="div"
            variant="h6"
            fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
            sx={{
              color: "#1976D2",
              marginLeft: "-8px",
              fontSize: 13,
              textAlign: "center",
            }}
          >
              Nessun obbligo
          </Typography>
        </div>
      </Card>
      <br />
    </Grid>
  );
};
