//React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//MUI
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
//Styles
import "./Styles.css";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//actions
import { setEmpYearsAct } from "../../services/Form/FormActions";

export const Step5 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [empYears, setEmpYears] = useState("none");

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep4") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep5";

  //validators and form building
  const validationSchema = yup.object({
    empYears: yup
      .string("Please select years of employment.")
      .required("Please select years of employment"),
  });

  const formik = useFormik({
    initialValues: {
      empYears,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setEmpYearsAct(empYears));
      Navigate("../step/6", { state: locState });
    },
  });

  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("empYears");
    if (data) {
      formik.setFieldValue("empYears", data);
      setEmpYears(data);
      // console.log("goals name after get", data);
    }
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("empYears", empYears);
    formik.setFieldValue("empYears", window.sessionStorage.getItem("empYears"));
  }, [empYears]);

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setEmpYearsAct(empYears));
  }, [empYears]);

  //event handling
  const Next = () => {
    formik.handleSubmit();
  };

  const handleThis = (e) => {
    setEmpYears(e.target.value);
  };
  // console.log(goals);

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  return (
    <Grid container direction="column" marginTop="60px">
      <Box>

        <Card
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            flexDirection: "column",
            backgroundColor: "#F7F7F7",
          }}
        >
          <React.Fragment>
            <div className="back-btn" onClick={onBack}>
              <IconButton>
                <ArrowBackIos />
              </IconButton>
            </div>
            <CardContent>
              <div>
                <FormControl variant="filled" className="centerMyForm">
                  <Typography
                    component="div"
                    variant="h3"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Merriweather  serif",
                      fontSize: "2.1rem",
                      lineHeight: "36px",
                      textAlign: "center",
                      marginBottom: "30px",
                    }}
                  >
                    Impiegato da
                  </Typography>                  <br />

                  <FormControl variant="filled" className="centerMyForm">
                    <InputLabel id="droplabel">Years of employment</InputLabel>
                    <Select
                      labelId="droplabel"
                      native
                      id="empYears"
                      name="empYears"
                      sx={{ width: "320px" }}
                      // data-testid="goals"

                      value={formik.values.empYears}
                      onInput={handleThis}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.empYears &&
                        Boolean(formik.errors.empYears)
                      }
                      //helperText={formik.touched.goals && formik.errors.goals}
                    >
                      <option value="none" selected disabled hidden></option>
                      <option value={1}>1 anno oppure meno di</option>
                      <option value={2}>2 anni </option>
                      <option value={3}>3 anni </option>
                      <option value={4}>4 anni </option>
                      <option value={5}>5 anni </option>
                      <option value={6}>più di 5 anni </option>
                    </Select>{" "}
                    <br />
                    <div className="buttonbox">
                      <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        className="button"
                        disabled={empYears == "none"}
                        data-testid="nextButton"
                        onClick={Next}
                      >
                        Prosegui
                      </Button>
                    </div>
                  </FormControl>
                </FormControl>
              </div>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
