import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './footer.css'
import logo from '../../assets/logo.png'
import React from "react";

export default function Footer(){
    return (

        <footer className="footer">
            <div className="ftr_main">
                <div className="container">
                    <a href="#" className="ftr_logo"><img src="/images/logo.svg" alt="" /></a>
                    <ul className="ftr_links">
                        <li><a href="#">Who We Are</a></li>
                        <li><a href="#">Sitemaps</a></li>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Services</a></li>
                        <li><a href="#">Privacy</a></li>
                    </ul>
                    <ul className="ftr_social_links">
                        <li><a href="#"><img src="/images/fb.svg" alt="" /></a></li>
                        <li><a href="#"><img src="/images/twitter.svg" alt="" /></a></li>
                        <li><a href="#"><img src="/images/instra.svg" alt="" /></a></li>
                    </ul>
                </div>
            </div>
            <div className="copy_right">
                <div className="container">
                    <p>© 2022 <a href="#">comparabanche</a></p>
                </div>
            </div>
        </footer>
    );
}
