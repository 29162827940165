//react
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//mui
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//actions
import {
  getProducts,
  SendfullState,
  SendStateToCompare,
  setEmail,
} from "../../services/Form/FormActions";
//styles
import "./Styles.css";

export const Step10 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [email, setEmailVal] = useState();
  const [error, setError] = useState(false);

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep9") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep10or12";

  //form building and validators
  const validationSchema = yup.object({
    email: yup
      .string("inserire un indirizzo di email valido.")
      .email("inserire un indirizzo di email valido.")
      .required("specificare email"),
  });

  const formik = useFormik({
    initialValues: {
      email,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      dispatch(setEmail(email));
      // dispatch(EnterPhone(document.getElementById('phone').value))
      Navigate("../step/11", { state: locState });
    },
  });
  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("email");
    formik.setFieldValue("email", data && data !== "null" ? data : "");
    setEmailVal(data);
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("email", email);
  }, [email]);

  // console.log("hello!", email);
  // console.log("getItem", window.sessionStorage.getItem("email"));

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setEmail(window.sessionStorage.getItem("email")));
  }, [email]);

  //event handling
  const Next = (e) => {
    formik.handleSubmit();
  };
  const handleThis = (e) => {
    e.preventDefault();
    setEmailVal(e.target.value);
  };
  // console.log("email in local state is: ", email);

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };

  const handleChecked = (e) => {
    e.preventDefault();
    setError(e.target.checked);
  };

  return (
    <Grid container direction="column" marginTop="60px">
      <Box>

        <Card
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            backgroundColor: "#F7F7F7",
          }}
        >
          <React.Fragment>
            <div className="back-btn" onClick={onBack}>
              <IconButton>
                <ArrowBackIos />
              </IconButton>
            </div>
            <CardContent>
              <FormControl variant="filled" className="centerMyForm">
                <Typography
                  component="div"
                  variant="h3"
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Merriweather  serif",
                    fontSize: "2.1rem",
                    lineHeight: "36px",
                    textAlign: "center",
                    marginBottom: "30px",
                  }}
                >
                  Indicare un indirizzo email per poter inviare un preventivo gratuito.
                </Typography><br />
                <TextField
                  className="inputSt"
                  required
                  variant="filled"
                  id="email"
                  label="indirizzo email"
                  placeholder="example@email.io"
                  sx={{ width: "320px", marginBottom: "10px" }}
                  data-testid="email"
                  value={formik.values.email}
                  onInput={handleThis}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                />
                <br />
                <div style={{ width: "400px" }}>
                  <FormControlLabel
                    className="checkbox"
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                    label={
                      <Typography fontFamily=" Lato, Helvetica Neue Arial, sans-serif">
                        Si, autorizzo il trattamento dei miei dati personali Mi potete contattare via telefono oppure email.
                      </Typography>
                    }
                    control={
                      <Checkbox onChange={handleChecked} type="checkbox" />
                    }
                    id="checkb1"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.checkb1 && Boolean(formik.errors.checkb1)
                    }
                    helperText={formik.touched.checkb1 && formik.errors.checkb1}
                  />
                </div>
                <br />
                <div className="buttonbox">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="button"
                    data-testid="nextButton"
                    disabled={!error}
                    onClick={() => {
                      Next();
                    }}
                  >
                    Prosegui
                  </Button>
                </div>
              </FormControl>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
