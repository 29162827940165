//React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//MUI
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
//Styles
import "./Styles.css";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//actions
import { setEmpStatus } from "../../services/Form/FormActions";

export const Step3 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [status, setStatus] = useState("none");

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep2") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep3";

  //validators and form building
  const validationSchema = yup.object({
    status: yup
      .string("Please select a status.")
      .required("Please select a status."),
  });

  const formik = useFormik({
    initialValues: {
      status,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setEmpStatus(status));
      Navigate("../step/4", { state: locState });
    },
  });

  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("status");
    if (data) {
      formik.setFieldValue("status", data);
      setStatus(data);

      // console.log("Status after Trim", data.trim());
    }
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("status", status);
    formik.setFieldValue("status", window.sessionStorage.getItem("status"));
  }, [status]);

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setEmpStatus(window.sessionStorage.getItem("status")));
  }, [status]);

  //event handling
  const Next = () => {
    formik.handleSubmit();
  };

  const handleThis = (e) => {
    setStatus(e.target.value);
  };
  // console.log(goals);

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  return (
    <Grid container direction="column" marginTop="60px">
      <Box>

        <Card
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            flexDirection: "column",
            backgroundColor: "#F7F7F7",
          }}
        >
          <React.Fragment>
            <div className="back-btn" onClick={onBack}>
              <IconButton>
                <ArrowBackIos />
              </IconButton>
            </div>
            <CardContent>
              <div>
                <FormControl variant="filled" className="centerMyForm">
                  <Typography
                    component="div"
                    variant="h3"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Merriweather  serif",
                      fontSize: "2.1rem",
                      lineHeight: "36px",
                      textAlign: "center",
                      marginBottom: "30px",
                    }}
                  >
                    Attuale tipo di contratto
                  </Typography>
                  <br />
                  <FormControl variant="filled" className="centerMyForm">
                    <InputLabel id="droplabel">Status</InputLabel>
                    <Select
                      labelId="droplabel"
                      native
                      id="status"
                      name="status"
                      sx={{ width: "320px" }}
                      // data-testid="goals"

                      value={formik.values.status}
                      onInput={handleThis}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      //helperText={formik.touched.goals && formik.errors.goals}
                    >
                      <option value="none" selected disabled hidden></option>
                      <option value="fixed_contract">Contratto a tempo indeterminato</option>
                      <option value="temporary_contract">
                        Contratto a tempo determinato
                      </option>
                      <option value="freelance">Libero professionista </option>
                      <option value="prisoner">Detenuto</option>
                      <option value="student">Studente</option>
                      <option value="uneployed">Disoccupato</option>
                    </Select>{" "}
                    <br />
                    <div className="buttonbox">
                      <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        className="button"
                        disabled={status == "none"}
                        data-testid="nextButton"
                        onClick={Next}
                      >
                        Prosegui
                      </Button>
                    </div>
                  </FormControl>
                </FormControl>
              </div>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
