//react
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//styles
import "./Styles.css";
//mui
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
//reusable component
import { Provider } from "../Provider";
import {
  getFilteredProducts,
  getFilteredProductsAge,
  getProducts,
} from "../../services/Form/FormActions";
import { useSelector } from "react-redux";

export const Step11 = () => {
  const Navigate = useNavigate();
  //navigation
  const location = useLocation();
  const LS = location.state;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [eligible, setEligible] = useState(false); //Determines whether a user is a eligible to get a loan.

  //Useful data
  const amount = parseFloat(window.sessionStorage.getItem("amount"));
  const rent = parseFloat(window.sessionStorage.getItem("rentCost"));
  const loanCost = parseFloat(
    JSON.parse(window.sessionStorage.getItem("loanCost"))
  );
  const empYears = JSON.parse(window.sessionStorage.getItem("empYears"));
  const status = window.sessionStorage.getItem("status");
  const age = JSON.parse(window.sessionStorage.getItem("birth"));
  const loanProbs = window.sessionStorage.getItem("loanProbs");
  const income = parseFloat(
    JSON.parse(window.sessionStorage.getItem("income"))
  );
  const months = JSON.parse(window.sessionStorage.getItem("months"));
  const email = window.sessionStorage.getItem("email");

  const user = {
    amount: amount,
    age: age,
    status: status,
    income: income,
    empYears: empYears,
    months: months,
    rent: rent,
    loanCost: loanCost,
    loanProbs: loanProbs,
    email: email,
  };
  console.log(user);

  const loanAcceptance = (user) => {
    let livingCost = 0.2 * user.income + user.rent + user.loanCost;
    let mb = 800; //monthly buffer
    let mlp = user.amount / 60; //Monthly Loan Payment
    let maxLoan = (user.income - livingCost - mlp - mb) * 60;

    console.log(maxLoan);

    if (user.amount > maxLoan) {
      setEligible(false);
      console.log("amount > max loan", maxLoan);
    } else if (user.loanProbs == "yes") {
      setEligible(false);
      console.log("problems with loans");
    } else if (user.amount < 3000) {
      setEligible(false);
      console.log("loan <3000");
    } else if (
      ["uneployed", "temporary_contract", "freelance"].includes(user.status)
    ) {
      setEligible(false);
      console.log("status problem");
    } else if (user.empYears <= 2) {
      setEligible(false);
      console.log("empYears <= 2");
    } else {
      setEligible(true);
    }
  };

  // const listProducts = async () => {
  //   setLoading(true);
  //   try {
  //     let result = await getProducts(parseFloat(user.amount));
  //     console.log(result);
  //     setData(result);
  //     console.log(data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(" sthg went wrong at listProducts() ");
  //     console.log(error);
  //   }
  // };

  const Products = JSON.parse(window.sessionStorage.getItem("products"));

  useEffect(() => {
    // listProducts();
    loanAcceptance(user);
  }, []);

  useEffect(() => {
    if (LS !== "fromStep10or12") {
      //come only from previous step
      Navigate(-1);
    }
  }, []);

  //event handling
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
//console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",Products)
  return (
    <Grid container direction="row">

      <Grid
        container
        direction="column"
        // alignItems="center"
        // justifyContent="center"
        marginTop="60px"
        className="title"
      >
        <div className="back-btn" onClick={onBack}>
          <IconButton>
            <ArrowBackIos />
          </IconButton>
        </div>
        <Typography
          component="div"
          variant="h5"
          sx={{
            fontWeight: 500,
            fontFamily: "Merriweather  serif",
            fontSize: "2rem",
            lineHeight: "36px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Questi sono i prestiti in linea con la richiesta:
        </Typography>

        {eligible && Products.length !== 0 ? (
          Products.filter(
            (elt) =>
              parseFloat(elt.properties[4].value.slice(0, -1)) <
                parseFloat(amount) ||
              parseFloat(elt.properties[0].value.slice(0, -1)) >
                parseFloat(amount)
          ).map((element, index) => {
            return (
              <Provider
                  key={index}
                  logo={element.product.productImg}
                  alt_logo={element.product.alt_pic}
                  clickUrl={element.product.linked[0].value.clickOutUrl}
                  title={element.product.title}
                  minimum_loan={element.properties[4].value}
                  maximum_loan={element.properties[0].value}
                  interest={element.properties[2].value}
                  loan_rate_indication={element.properties[3].value}

              />
            );
          })
        ) : (
          <Typography
            component="div"
            variant="h5"
            color="darkred"
            sx={{
              fontWeight: 500,
              fontFamily: "Merriweather  serif",
              fontSize: "2rem",
              lineHeight: "36px",
              textAlign: "left",
              marginLeft: "20%",
              marginBottom: "20px",
            }}
          >
            Non è disponibile alcun prodotto.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
