//React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//MUI
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//Actions
import { setAge } from "../../services/Form/FormActions";
//Styles
import "./Styles.css";
import { element } from "prop-types";

export const Step2 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  //STATE
  const [disableButton, setDisableButton] = useState(true);
  const [bDate, setBDate] = useState();

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep1") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);

  let locState = "fromStep2";

  //form building and validators
  const validationSchema = yup.object().shape({
    bDate: yup.string().required("Your age is required"),
  });
  const formik = useFormik({
    initialValues: {
      bDate,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //Dispatch a date action
      if (values.bDate) {
        dispatch(setAge(calculateAge(bDate)));
      }
    },
  });
  function calculateAge(birthDate) {
    birthDate = new Date(birthDate);
    var otherDate = new Date();

    var years = otherDate.getFullYear() - birthDate.getFullYear();

    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() == birthDate.getMonth() &&
        otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }

    return years;
  }

  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("birth");
    formik.setFieldValue(
      "bDate",
      data && data !== "null" && data !== "undefined" ? data : ""
    );
    setBDate(data && data !== "null" && data !== "undefined" ? data : "");
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("birth", calculateAge(bDate));
    //Dispatch a Birth Action!!!
    dispatch(setAge(calculateAge(bDate)));
  }, [bDate]);

  useEffect(() => {
    if (
      document.getElementById("birthDate") !== "" &&
      document.getElementById("birthDate") != NaN
    ) {
      setBDate(calculateAge(document.getElementById("birthDate")));
      window.sessionStorage.setItem("birth", calculateAge(bDate));
      dispatch(setAge(calculateAge(bDate)));
    }
  }, []);
  //events
  const Next = () => {
    formik.handleSubmit();
    //pass a state with the new path. to know where i come from.
    Navigate("../step/3", { state: locState });
  };

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  const handleBirthDateChange = (newValue) => {
    setBDate(newValue);
  };

  return (
    <Grid container direction="column" width="100%" marginTop="60px">
      <Box>

        <Card
          className="bod"
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            backgroundColor: "#F7F7F7",
          }}
        >
          <div className="back-btn" onClick={onBack}>
            <IconButton>
              <ArrowBackIos />
            </IconButton>
          </div>
          <React.Fragment>

            <CardContent>
              <FormControl className="centerMyForm">
                <Typography
                  component="div"
                  variant="h3"
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Merriweather  serif",
                    fontSize: "2.1rem",
                    lineHeight: "36px",
                    textAlign: "center",
                    marginBottom: "25px",
                  }}
                >
                  Età
                </Typography>
                <br />
                <Grid
                  container
                  direction="column"
                  width="320px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Data di nascita"
                      inputFormat="MM/dd/yyyy"
                      value={bDate}
                      id="birthDate"
                      onChange={handleBirthDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <br />
                <div className="buttonbox">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="button"
                    data-testid="nextButton"
                    // disabled={!hasRegister}
                    onClick={Next}
                  >
                    Prosegui
                  </Button>
                </div>
              </FormControl>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
