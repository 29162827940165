//React
import React from "react";
import { useNavigate } from "react-router-dom";
//MUI tools
import Button from "@mui/material/Button";
import { CardMedia, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
//styles


import Header from '../Header/Header';
import Footer from '../Footer/Footer';






import business from "../../assets/firstpic.jpg";
import Avatar from "@mui/material/Avatar";

export const Home = () => {
  const Navigate = useNavigate();

  const login = (e) => {
    e.preventDefault();
    Navigate("../login");
  };

  const Next = (e) => {
    e.preventDefault();
    Navigate("../step/1");
  };
  return (
<div>

    <div className="home-banner-sec" >

        <div className="bannr-dot-bg-imgon">
            <figure>
                <img src="/images/bannr-dot1.svg" alt="" />
            </figure>
        </div>
        <div className="bannr-dot-bg-imgtw">
            <figure><img src="/images/bannr-dot2.svg"  alt="" /></figure>
        </div>
        <div className="container">
            <div className="home-banner-innr">
                <h1>Trova, confronta e scegli il <br /> <span>miglior prodotto finanziario</span></h1>
                <p>Più di 10.000 persone hanno optato per questo.</p>
                <form action="https://prestiti.comparabanche.it/step/1" method="POST">
                    <div className="home-banr-form">
                        <input type="submit" name="submit"   onClick={Next} value="Confronta" />
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div className="why-would-use-sec common-gap">
        <div className="container">
            <div className="section-head center-txt blue-txt">
                <h2>Perché un consumatore dovrebbe utilizzare <span>il nostro servizio?</span></h2>
            </div>
            <div className="row why-would-use-rw">
                <div className="col-lg-4 col-md-6 why-would-use-clm">
                    <div className="why-would-srv-crt-wrap">
                        <div className="why-would-use-top-icn">
                            <i><img src="/images/cons-we-srv1.svg" alt /></i>
                        </div>
                        <h5>Gratuito</h5>
                        <p>Il nostro preventivo è gratuito e senza obblighi.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 why-would-use-clm">
                    <div className="why-would-srv-crt-wrap">
                        <div className="why-would-use-top-icn">
                            <i><img src="/images/cons-we-srv2.svg" alt /></i>
                        </div>
                        <h5>Sicuro</h5>
                        <p>I dati sono protetti da un sistema di sicurezza.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 why-would-use-clm">
                    <div className="why-would-srv-crt-wrap">
                        <div className="why-would-use-top-icn">
                            <i><img src="/images/cons-we-srv3.svg" alt /></i>
                        </div>
                        <h5>Veloce</h5>
                        <p>Possiamo confrontare e trovare in pochi minuti la soluzione più vantaggiosa per te.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="home-lan-calclte-sec common-gap">
        <div className="loan-cal-img-extra-on">
            <figure><img src="/images/lon-cal-img-extra1svg.svg" alt="" /></figure>
        </div>
        <div className="container">
            <div className="row home-lan-calclte-rw">
                <div className="col-lg-6 home-lan-calclte-clm">
                    <div className="home-lan-calclte-lft-img">
                        <div className="home-lan-calclte-img-lft-lft">
                            <div className="home-lan-calclte-tw-img-one-tp-one">
                                <figure><img src="/images/home-lan-calclte-tw-img2.png" alt /></figure>
                                <div className="txt-hm-calclt-innr">
                                    <i><img src="/images/img-icn-calct2.svg" alt /></i>
                                    <h4>10.000€</h4>
                                    <h5>Valore del prestito</h5>
                                </div>
                            </div>
                        </div>
                        <div className="home-lan-calclte-tw-img">
                            <div className="home-lan-calclte-tw-img-one-tp">
                                <figure><img src="/images/home-lan-calclte-tw-img1.png" alt /></figure>
                                <div className="txt-hm-calclt-innr">
                                    <i><img src="/images/img-icn-calct1.svg" alt /></i>
                                    <h4>10%</h4>
                                    <h5>Tasso d'interesse</h5>
                                </div>
                            </div>
                            <div className="home-lan-calclte-tw-img-tww-tp">
                                <figure><img src="/images/home-lan-calclte-tw-img3.png" alt /></figure>
                                <div className="txt-hm-calclt-innr">
                                    <i><img src="/images/img-icn-calct3.svg" alt /></i>
                                    <h4>11.000€</h4>
                                    <h5>Ammontare totale del prestito</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 home-lan-calclte-clm">
                    <div className="section-head blue-txt">
                        <h2>Esempio di calcolo <span>del prestito</span></h2>
                        <p>Ogni mese aiutiamo i nostri lettori a trovare le offerte migliori attraverso un preventivo gratuito, veloce e senza obblighi.</p>
                        <p>Il nostro comparatore gratuito permette di confrontare le soluzioni di prestito più adatte alle proprie esigenze.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="home-less-alles-sec">
        <div className="container">
            <div className="home-less-alles-bg-img-extra-tw">
                <figure><img src="/images/home-less-alles-bg-crcl1.svg" alt="" /></figure>
            </div>
            <div className="section-head center-txt white-txt">
                <h2>Leggi cosa scrivono <span>i nostri esperti</span></h2>
            </div>
            <div className="row home-less-alles-rw">
                <div className="col-lg-4 col-md-6 home-less-alles-clm">
                    <div className="home-less-alles-crt-wrp">
                        <figure><img src="/images/home-less-alles-sec1.jpg" alt="" /></figure>
                        <div className="wrap-crt-mn-innr-home-less-alles">
                            <div className="home-less-alles-dtls">
                                <figure><img src="/images/home-less-alles-usr1.png" alt="" /></figure>
                                <div className="home-less-alles-usr-dtls">
                                    <h5>Mary Elizabeth Attiani</h5>
                                    <span>6 Gennaio, 2020</span>
                                </div>
                            </div>
                            <p>Cosa fare per cambiare banca?</p>
                            <a href="#">leggi articolo<i><img src="/images/arww1.svg" alt="" /></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 home-less-alles-clm">
                    <div className="home-less-alles-crt-wrp">
                        <figure><img src="/images/home-less-alles-sec2.jpg" alt="" /></figure>
                        <div className="wrap-crt-mn-innr-home-less-alles">
                            <div className="home-less-alles-dtls">
                                <figure><img src="/images/home-less-alles-usr2.png" alt="" /></figure>
                                <div className="home-less-alles-usr-dtls">
                                    <h5>Mary Elizabeth Attiani</h5>
                                    <span>16 Maggio, 2019</span>
                                </div>
                            </div>
                            <p>Come e quanto sono cresciuti i prestiti personali online in Italia?</p>
                            <a href="#">leggi articolo<i><img src="/images/arww1.svg" alt="" /></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 home-less-alles-clm">
                    <div className="home-less-alles-crt-wrp">
                        <figure><img src="/images/home-less-alles-sec3.jpg" alt="" /></figure>
                        <div className="wrap-crt-mn-innr-home-less-alles">
                            <div className="home-less-alles-dtls">
                                <figure><img src="/images/home-less-alles-usr1.png" alt="" /></figure>
                                <div className="home-less-alles-usr-dtls">
                                    <h5>Mary Elizabeth Attiani</h5>
                                    <span>10 Maggio, 2021</span>
                                </div>
                            </div>
                            <p>I migliori prestiti personali in Italia 2021</p>
                            <a href="#">leggi articolo<i><img src="/images/arww1.svg" alt="" /></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="home-zo-doen-sec common-gap">
        <div className="bg-extra-zo-doen-img">
            <figure><img src="/images/bg-extra-crc99.svg" alt="" /></figure>
        </div>
        <div className="container">
            <div className="row home-zo-doen-rw">
                <div className="col-lg-6 home-zo-doen-clm">
                    <div className="innr-wrap-lft-zo-doen">
                        <div className="section-head blue-txt">
                            <h2>Così facciamo la nostra <span>ricerca</span></h2>
                            <h5>Tra gli aspetti che guardiamo ci sono anche:</h5>
                            <ul>
                                <li><p>Costi fissi e variabili (es. spese per l'utente o di prelievo )</p></li>
                                <li><p>Interesse (es. interessi di risparmio oppure penalità in caso di prelievo oppure conto in rosso)</p></li>
                                <li><p>Facilità di gestione (es. solo digitale, App disponibile)</p></li>
                                <li><p>Qualità del servizio (es. servizio clienti)</p></li>
                                <li><p>Sicurezza (es. sistema di garanzia dei depositi)</p></li>
                                <li><p>Sostenibilità/etica (es. azienda sostenibile?)</p></li>
                            </ul>
                            <a className="common-btn" href="#">Per saperne di più su Comparabanche.it</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 home-zo-doen-clm">
                    <div className="home-zo-doen-img-wrap">
                        <span><img src="/images/dot-blu11.svg" alt="" /></span>
                        <figure><img src="/images/home-zo-doen1.jpg" alt="" /></figure>
                        <div className="home-zo-doen-img-wrap-bx-txt">
                            <div className="hm-zo-dns-box-sngl">
                                <i><img src="/images/hm-zo-dns-icn111.svg" alt="" /></i>
                                <h4>1.000</h4>
                                <p>Prodotti finanziari analizzati</p>
                            </div>
                            <div className="hm-zo-dns-box-sngl">
                                <i><img src="/images/hm-zo-dns-icn2.svg" alt="" /></i>
                                <h4>40</h4>
                                <p>Impostazioni finanziarie</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="faq_sec common-gap pt-0">
        <div className="container">
            <div className="section-head center-txt blue-txt">
                <h2>Domande poste <span>frequentemente</span></h2>
            </div>
            <div className="faq_accordion_wrap">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                1. Cos'è un prestito ?
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>Il prestito è importo concesso da una banca oppure da una società finanziaria ad un soggetto che necessità di denaro per liquidità oppure ad esempio per acquistare un'auto, rinnovare la casa o per altri eventuali motivi. L’istituto, erogando il prestito, applica anche un tasso di interesse, costituito da Tan e Taeg a rata solitamente fissa. La scelta è ampia, ma vi sono alcune finanziarie particolarmente conosciute e popolari, ognuna prevede un periodo ed un capitale minimo e massimo di finanziamento.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                2. Tipi di prestito personale ?
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>Esistono diverse tipologie di prestito: il prestito domestico, per rinnovare o ristrutturare casa; il prestito per la riqualificazione energetica della propria abitazione; il prestito per acquistare un’auto; il prestito per poter sostenere spese mediche impreviste; il prestito per potersi sposare o andare in vacanza.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                3. Come ottenere prestiti online ?
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>In genere si può ottenere in poco tempo ed online la richiesta risulta essere ancora più veloce, ma sono necessari alcuni requisiti fondamentali che a volte possono un po’ divergere in base al tipo di finanziamento oppure ai criteri dell’istituto bancario, ma tre aspetti sono essenziali: l’età del richiedente di minimo 18 e massimo 70 anni (anche se alcune banche e finanziarie dispongono di finanziamenti per gli over 70); un reddito dimostrabile e la titolarità di un conto corrente bancario. Vi possono essere poi altri requisiti, quali il credit scoring, per misurare la solvibilità del cliente. Tale affidabilità viene anche valutata sulla base del rapporto tra la rata di rimborso e il reddito del richiedente che, in genere, non deve superare il 30%.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                4. TAN e TAEG: cosa sono ?
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>Al prestito vengono applicati dei tassi di interesse detti Tan e Taeg, che si intendono come il tasso annuale nominale ed il tasso annuale effettivo globale. Il TAN è il tasso di interesse puro, l’interesse calcolato sul prestito maturato al termine dell’anno e che va riconosciuto al finanziatore. Il TAEG è l’indicatore per dichiarare il costo globale del finanziamento, espresso in percentuale, incluso di spese obbligatorie per apertura, concessione e rimborso del credito erogato. Quindi, in genere, è sempre più alto del TAN.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</div>


  );
};
