//importing action types
import { FormActionTypes } from "./formActionTypes";

const initialState = {
  //Form state
  Amount: "",
  Age: "",
  empStatus: "",
  NetIncome: "",
  EmpYears: "",
  MonthsPerYear: "",
  rentCosts: "",
  loanCosts: "",
  loanProbs: "",
  Email: "",
  Products: "",
};

export const InfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FormActionTypes.SET_LOAN:
      return { ...state, Amount: payload };

    case FormActionTypes.SET_AGE:
      return { ...state, Age: payload };

    case FormActionTypes.SET_EMPSTATUS:
      return { ...state, empStatus: payload };

    case FormActionTypes.SET_NETINCOME:
      return { ...state, NetIncome: payload };

    case FormActionTypes.SET_EMPYEARS:
      return { ...state, EmpYears: payload };

    case FormActionTypes.SET_MONTHS:
      return { ...state, MonthsPerYear: payload };

    case FormActionTypes.SET_RENT_COST:
      return { ...state, rentCosts: payload };

    case FormActionTypes.SET_LOAN_COSTS:
      return { ...state, loanCosts: payload };
    case FormActionTypes.SET_LOAN_PROBS:
      return { ...state, loanProbs: payload };

    case FormActionTypes.SET_EMAIL:
      return { ...state, Email: payload };

    case FormActionTypes.SET_PRODUCTS:
      return { ...state, Products: payload };

    default:
      return state;
  }
};
