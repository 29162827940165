//React, Redux Imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import "./Styles.css";
//MUI Imports
import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
//Formik & Yup
import { useFormik } from "formik";
import * as yup from "yup";
import Footer from '../Footer/Footer';

//Import Actions
import {
  setLoan,
  getLimits,
  getProducts,
  setProducts,
} from "../../services/Form/FormActions";
import Avatar from "@mui/material/Avatar";

export const Step1 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // console.log(amountSlice);

  //Navigation
  let locState = "fromStep1";

  //What if the user's amount is less than required?
  // const compareLoans = async (amount) => {
  //   let res = await getLimits();
  //   if (res.minL > amount) {
  //     return "Je gewenste leenbedrag is te laag.";
  //   } else if (res.maxL < amount) {
  //     return "Je gewenste leenbedrag is te hoog.";
  //   } else {
  //     return "";
  //   }
  // };

  //state Management
  useEffect(() => {
    const data = window.sessionStorage.getItem("amount");
    formik.setFieldValue("amount", data && data !== "null" ? data : "");
    setAmount(data);
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("amount", amount);
  }, [amount]);

  // console.log("hello!", amount);
  // console.log("getItem", window.sessionStorage.getItem("amount"));

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setLoan(window.sessionStorage.getItem("amount")));
  }, [amount]);
  //Form Builder and Validations:
  const validationSchema = yup.object({
    amount: yup
      .number()
      .typeError("specificare l’importo")
      .required("È richiesto l’importo"),
  });

  const formik = useFormik({
    initialValues: {
      amount,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setLoan(amount));
      if (values.amount !== "") {
        //pass a state with the new path. to know where i come from.
        Navigate("../step/2", { state: locState });
      }
    },
  });
  //getting products
  const listProducts = async () => {
    setLoading(true);
    try {
      let result = await getProducts().then((response) => {
        setData(response);
        console.log(response);
        setLoading(false);
      });
    } catch (error) {
      console.log(" sthg went wrong at listProducts() ");
      console.log(error);
    }
  };
  useEffect(() => {
    listProducts();
  }, []);

  useEffect(() => {
    dispatch(setProducts(data));
    window.sessionStorage.setItem("products", JSON.stringify(data));
    console.log(JSON.parse(window.sessionStorage.getItem("products")));
  }, [data]);
  //handling events
  const Next = async (e) => {
    // let error = await compareLoans(amount);
    // if (error) {
    //   setError(error);
    // } else {
    formik.handleSubmit();
  };
  const handleThis = (e) => {
    e.preventDefault();
    setAmount(e.target.value);
  };
  // console.log("amount is: ", amount);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      // justifyContent="center"
      marginTop="60px"
      width="100%"
    >


      <Box>
        <Card
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F7F7F7",
            boxShadow: "none",
          }}
        >
          <React.Fragment>
            <CardContent>
              <FormControl className="centerMyForm">
                <Typography
                  component="div"
                  variant="h3"
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Merriweather  serif",
                    fontSize: "2rem",
                    lineHeight: "36px",
                    textAlign: "center",
                  }}
                  className="title"
                >
                  importo del prestito
                </Typography>
                <br />
                <TextField
                  required
                  label="Importo"
                  variant="filled"
                  id="amount"
                  name="amount"
                  data-testid="amount"
                  sx={{ width: "320px" }}
                  value={formik.values.amount}
                  onInput={handleThis}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (error) {
                      setError("");
                    }
                    formik.handleChange(e);
                  }}
                  error={
                    error
                      ? true
                      : formik.touched.amount && Boolean(formik.errors.amount)
                  }
                  helperText={
                    error
                      ? error
                      : formik.touched.amount && formik.errors.amount
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />
                <br />
                <div className="buttonbox">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="button"
                    data-testid="nextButton"
                    onClick={Next}
                  >
                    Prosegui
                  </Button>
                </div>
              </FormControl>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>

);
};
