//React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//MUI
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
//Styles
import "./Styles.css";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//actions
import { setMonthsPerYear } from "../../services/Form/FormActions";

export const Step6 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [months, setMonths] = useState("none");

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep5") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep6";

  //validators and form building
  const validationSchema = yup.object({
    months: yup
      .string("Please select paid months per year.")
      .required("Please select paid months per year."),
  });

  const formik = useFormik({
    initialValues: {
      months,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setMonthsPerYear(months));
      Navigate("../step/7", { state: locState });
    },
  });

  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("months");
    if (data) {
      formik.setFieldValue("months", data);
      setMonths(data);
      // console.log("goals name after get", data);
    }
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("months", months);
    formik.setFieldValue("months", window.sessionStorage.getItem("months"));
  }, [months]);

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setMonthsPerYear(months));
  }, [months]);

  //event handling
  const Next = () => {
    formik.handleSubmit();
  };

  const handleThis = (e) => {
    setMonths(e.target.value);
  };
  // console.log(goals);

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  return (
    <Grid container direction="column" marginTop="60px">
      <Box>

        <Card
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            flexDirection: "column",
            backgroundColor: "#F7F7F7",
          }}
        >
          <React.Fragment>
            <div className="back-btn" onClick={onBack}>
              <IconButton>
                <ArrowBackIos />
              </IconButton>
            </div>
            <CardContent>
              <div>
                <FormControl variant="filled" className="centerMyForm">
                  <Typography
                    component="div"
                    variant="h3"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Merriweather  serif",
                      fontSize: "2.1rem",
                      lineHeight: "36px",
                      textAlign: "center",
                      marginBottom: "30px",
                    }}
                  >
                    Numero delle mensilità
                  </Typography><br />
                  <FormControl variant="filled" className="centerMyForm">
                    <InputLabel id="droplabel">
                      mesi
                    </InputLabel>
                    <Select
                      labelId="droplabel"
                      native
                      id="months"
                      name="months"
                      sx={{ width: "320px" }}
                      // data-testid="goals"

                      value={formik.values.months}
                      onInput={handleThis}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.months && Boolean(formik.errors.months)
                      }
                      //helperText={formik.touched.goals && formik.errors.goals}
                    >
                      <option value="none" selected disabled hidden></option>
                      <option value={12}>12 mesi</option>
                      <option value={13}>13 mesi</option>
                      <option value={14}>14 mesi</option>
                      <option value={15}>15 mesi</option>
                    </Select>{" "}
                    <br />
                    <div className="buttonbox">
                      <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        className="button"
                        disabled={months == "none"}
                        data-testid="nextButton"
                        onClick={Next}
                      >
                        Prosegui
                      </Button>
                    </div>
                  </FormControl>
                </FormControl>
              </div>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
