//react
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//mui
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

//styles
import "./Styles.css";

export const Thanks = () => {
  const Navigate = useNavigate();

  //Selecting useful bits of state:
  const [logo, setLogo] = useState();
  const [title, setTitle] = useState();
  const [loan, setLoan] = useState();
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [rate, setRate] = useState();

  //navigation
  const location = useLocation();
  useEffect(() => {
    if (location.state !== "fromStep11") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep10or12";
  useEffect(() => {
    const d = JSON.parse(window.sessionStorage.getItem("offer"));
    console.log(d);
    setLogo(d.logo);
    setLoan(window.sessionStorage.getItem("amount"));
    setTitle(d.title);
    setMin(d.minimum_loan);
    setMax(d.maximum_loan);
    setRate(d.loan_rate_indication);
  }, []);

  //event handling
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };

  const Next = (e) => {
    e.preventDefault();
    Navigate("../step/11", { state: locState }, { replace: true });
  };
  return (
    <Grid
      container
      direction="row"
      // marginTop="60px"
    >
      <div className="back-btn" onClick={onBack}>
        <IconButton>
          <ArrowBackIos />
        </IconButton>
      </div>
      <Grid
        container
        direction="row"
        marginBottom="20px"
        justifyContent="center"
        className="view"
      >
        <Card
          className="formCard"
          sx={{
            justifyContent: "center",
            backgroundColor: "#F7F7F7",
            boxShadow: "none",
            // minWidth: 400,
            marginLeft: "15px",
          }}
        >
          <div style={{ width: "220px" }}>
            <Typography
              component="div"
              variant="h6"
              fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
              sx={{
                marginTop: "6px",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
                Riepilogo
            </Typography>
            <Card
              sx={{
                border: 2,
                borderColor: "lightblue",
                borderRadius: 1,
                boxShadow: "none",
                height: "auto",
                backgroundColor: "#F7F7F7",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <Typography
                component="div"
                variant="h6"
                fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                sx={{
                  marginLeft: "9px",
                  fontSize: 13,
                }}
              >
                  Prestito personale
              </Typography>
              <CardMedia
                component="img"
                sx={{
                  border: 1,
                  borderColor: "darkgray",
                  borderRadius: 1,
                  marginLeft: "8px",
                  marginTop: "6px",
                }}
                image={logo}
                alt={title}
                style={{ height: "50px", width: "120px" }}
              />
              <div>
                <Typography
                  component="div"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    marginTop: "6px",
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  {title}
                </Typography>
                <Divider light />

                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                      Ammontare del prestito : €
                  </strong>{" "}
                  {loan}
                </Typography>

                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                      importo minimo : €
                  </strong>{" "}
                  {min}
                </Typography>

                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                      importo massimo : €
                  </strong>{" "}
                  {max}
                </Typography>
                <Typography
                  component="div"
                  variant="subtitle"
                  sx={{
                    marginTop: "6px",
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong
                    style={{
                      fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                    }}
                  >
                      Tasso interesse :
                  </strong>{" "}
                  {rate}
                </Typography>
              </div>
            </Card>
          </div>
        </Card>
        <Box sx={{ marginTop: "30px", marginLeft: "30px" }}>
          <Typography
            component="div"
            variant="h6"
            fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
            sx={{
              marginTop: "6px",
              fontSize: 20,
              fontWeight: 600,
              marginLeft: 1,
            }}
          >
              Grazie per la richiesta
          </Typography>
          <Button
            variant="contained"
            onClick={Next}
            sx={{
              marginLeft: "10px",
              alignItems: "center",
              marginRight: "10px",
              marginTop: "20px",
              justifyContent: "10px",
              width: "auto",
            }}
          >
              Fai un’ulteriore richiesta.
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
