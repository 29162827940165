//React Tools
import * as React from "react";
import { useNavigate } from "react-router-dom";
//MUI tools
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
//styles
import "./Header.css";

export const Header = () => {
  const Navigate = useNavigate();

  return (
      <header className="main-head">
          <div className="container">

              <nav className="navbar navbar-expand-lg">

                  <a className="navbar-brand" href="/"><img src="/images/logo.svg"  alt="" /></a>

              </nav>

          </div>


      </header>
  );
};
export default Header;
