//React
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
//MUI
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//actions
import { setLoanCosts } from "../../services/Form/FormActions";
//styles
import "./Styles.css";

export const Step8 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [loanCost, setLoanCost] = useState("");
  const [error, setError] = useState("");

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep7") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep8";

  //form builder and validators
  //Form Builder and Validations:
  const validationSchema = yup.object({
    loanCost: yup
      .number()
      .typeError("specificare l’importo.")
      .required("L’importo è richiesto."),
  });
  const formik = useFormik({
    initialValues: {
      loanCost,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setLoanCosts(loanCost));
      Navigate("../step/9", { state: locState });
    },
  });

  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("loanCost");
    if (data) {
      formik.setFieldValue("loanCost", data && data !== "null" ? data : "");
      setLoanCost(data);
      // console.log("clients after get", data);
    }
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem("loanCost", loanCost);
    formik.setFieldValue("loanCost", window.sessionStorage.getItem("loanCost"));
    dispatch(setLoanCosts(loanCost));
  }, [loanCost]);

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setLoanCosts(window.sessionStorage.getItem("loanCost")));
  }, [loanCost]);
  //event handling
  const Next = () => {
    formik.handleSubmit();
  };
  const handleThis = (e) => {
    e.preventDefault();
    setLoanCost(e.target.value);
  };
  // console.log("clients after useState", clients);

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  return (
    <Grid container direction="column" marginTop="60px">
      <Box>

        <Card
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            flexDirection: "column",
            backgroundColor: "#F7F7F7",
          }}
        >
          <React.Fragment>
            <div className="back-btn" onClick={onBack}>
              <IconButton>
                <ArrowBackIos />
              </IconButton>
            </div>
            <CardContent>
              <FormControl variant="filled" className="centerMyForm">
                <Typography
                  component="div"
                  variant="h3"
                  sx={{
                    fontWeight: 500,
                    fontFamily: "Merriweather  serif",
                    fontSize: "2.1rem",
                    lineHeight: "36px",
                    textAlign: "center",
                    marginBottom: "30px",
                  }}
                >
                  Quali sono i costi fissi mensili per prestiti personali esistenti?
                </Typography>
                <br />
                <TextField
                  required
                  label="costi prestito mensile"
                  variant="filled"
                  id="loanCost"
                  name="loanCost"
                  data-testid="loanCost"
                  sx={{ width: "320px" }}
                  value={formik.values.loanCost}
                  onInput={handleThis}
                  onChange={(e) => {
                    if (error) {
                      setError("");
                    }
                    formik.handleChange(e);
                  }}
                  error={
                    error
                      ? true
                      : formik.touched.loanCost &&
                        Boolean(formik.errors.loanCost)
                  }
                  helperText={
                    error
                      ? error
                      : formik.touched.loanCost && formik.errors.loanCost
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                />
                <br />
                <br />

                <div className="buttonbox">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    className="button"
                    data-testid="nextButton"
                    onClick={Next}
                  >
                    Prosegui
                  </Button>
                </div>
              </FormControl>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
