//React
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
//MUI
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FormControlLabel, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//actions
import { setLoanProbs } from "../../services/Form/FormActions";
//styles
import "./Styles.css";

export const Step9 = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [loanProbs, setLoanProbsV] = useState("");
  const [open, setOpen] = useState(false);
  const [pass, setPass] = useState(false);

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep8") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep9";

  //form builder and validators
  const validationSchema = yup.object({
    loanProbs: yup.string("Select an answer").required("Select an answer"),
  });
  const formik = useFormik({
    initialValues: {
      loanProbs: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  //State Management:
  //To make sure that state is persistant on refresh.
  useEffect(() => {
    const data = window.sessionStorage.getItem("loanProbs");

    formik.setFieldValue("loanProbs", data);
    dispatch(setLoanProbs(data));
  }, []);
  //OnChange:
  useEffect(() => {
    if (loanProbs !== "") {
      window.sessionStorage.setItem("loanProbs", loanProbs);
      dispatch(setLoanProbs(loanProbs));
    }
  }, [loanProbs]);

  //event handling
  const Next = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    Navigate("../step/10", { state: locState });
  };
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.value == "yes") {
      setLoanProbsV("yes");
      e.target.checked = true;
    } else {
      setLoanProbsV("no");
      e.target.checked = true;
    }
    setPass(true);
  };

  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  return (
    <Grid container direction="column" marginTop="60px">
      <Box>

        <Card
          sx={{
            minHeight: "250px",
            minWidth: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            flexDirection: "column",
            backgroundColor: "#F7F7F7",
          }}
        >
          <React.Fragment>
            <div className="back-btn" onClick={onBack}>
              <IconButton>
                <ArrowBackIos />
              </IconButton>
            </div>
            <CardContent>
              <div>
                <FormControl variant="filled" className="centerMyForm">
                  <Typography
                    component="div"
                    variant="h3"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Merriweather  serif",
                      fontSize: "2.1rem",
                      lineHeight: "36px",
                      textAlign: "center",
                      marginBottom: "30px",
                    }}
                  >
                    Hai avuto problemi a pagare prestiti in passato?
                  </Typography>

                  <br />
                  <FormControl variant="filled" className="centerMyForm">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="loanProbs"
                      id="loanProbs"
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="si"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="no"
                      />
                    </RadioGroup>

                    <br />
                    <div className="buttonbox">
                      <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        className="button"
                        disabled={!pass}
                        data-testid="nextButton"
                        onClick={Next}
                      >
                        Prosegui
                      </Button>
                    </div>
                  </FormControl>
                </FormControl>
              </div>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </Grid>
  );
};
